<template>
  <b-overlay :show="showOverlay">
    <b-row>
      <b-col>
        <b-form-group label="Année :" label-for="year">
          <b-form-select
            id="year"
            v-model="form.year"
            :options="years"
            @change="getEvents"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
       <b-col>
        <b-form-group label="Niveau :" label-for="level">
          <b-form-select
            id="level"
            v-model="form.level"
            :options="levels"
            @change="updateTable"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          striped borderless small
          :items="filteredEvents"
          :fields="fields"
        >
          <template #head(details)="">
            <b-icon-plus-circle
              scale="0.80"
              @click="toggleDetailsAllRows(events)"
            />
          </template>
          <template v-slot:cell(details)="row">
            <b-icon-plus-circle scale="0.80" @click="row.toggleDetails"/>
          </template>
          <template v-slot:row-details="row">
            <b-card class="card-small-padding">
              <div v-for="race of row.item.races" :key="race.id">
                <router-link :to="'/race/'+race.id">
                  {{ $DateTime.fromISO(race.date).toISODate() + ' - ' + race.name }}
                </router-link>
              </div>
            </b-card>
          </template>
          <template v-slot:cell(eventDate)="data">
            {{ $DateTime.fromISO(data.item.date).toISODate()}}
          </template>
           <template v-slot:cell(level)="data">
            {{getDivision(data.item.level)}}
          </template>
          <template v-slot:cell(name)="data">
            <a href="#" @click="data.toggleDetails">
              {{ getEventName(data.item.name, data.item.place) }}
            </a>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import orderBy from 'lodash.orderby';
import { getDivision, getEventName } from '../lib/race';

export default {
  name: 'Results',
  data() {
    const currentYear = (new Date()).getFullYear();
    const firstYear = 2001;
    const years = Array(currentYear - firstYear + 1).fill().map((_, idx) => firstYear + idx);
    return {
      showOverlay: false,
      events: [],
      filteredEvents: [],
      fields: [
        { key: 'details', label: '' },
        { key: 'eventDate', label: 'Date' },
        { key: 'level', label: 'Niveau' },
        { key: 'name', label: 'Intitulé' },
      ],
      years,
      levels: [
        { value: 'all', text: 'Tous' },
        { value: 5, text: 'Chpts' },
        { value: 4, text: 'N1' },
        { value: 3, text: 'N2' },
        { value: 2, text: 'N3' },
        { value: 1, text: 'Reg' },
      ],
      form: {
        year: currentYear,
        level: 'all',
      },
    };
  },
  async created() {
    await this.getEvents();
  },
  methods: {
    async getEvents() {
      this.showOverlay = true;
      const response = await this.axios.get(`/ffck/events?year=${this.form.year}`);
      if (response && response.data) {
        this.events = response.data.events;
      }
      this.updateTable();
      this.showOverlay = false;
    },
    async updateTable() {
      this.filteredEvents = this.events;
      if (this.form.level !== 'all') {
        this.filteredEvents = this.events.filter((e) => e.level === this.form.level);
      }

      this.filteredEvents = orderBy(this.filteredEvents, ['date'], ['desc']);
    },
    toggleDetailsAllRows(rows) {
      rows.forEach((row) => {
        // eslint-disable-next-line no-underscore-dangle, no-param-reassign
        row._showDetails = !row._showDetails;
      });
      this.updateTable();
    },
    getDivision,
    getEventName,
  },
};
</script>

<style scoped>
  div {
    font-family:'calibriregular';
    font-size:14px;
    color:#7b7777;
    font-weight:400;
  }
  p {
    font-size:16px;
  }
  table {
    font-size:12px;
  }
  select {
    font-size:14px;
  }
  .page-item.active .page-link {
    background-color: #549fd0;
    border-color: #549fd0;
  }

  .page-link {
   color: #549fd0;
  }
</style>
